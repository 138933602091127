import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <body className="bg-white">
        <header className="p-7 flex justify-between">
          <h1 className="text-5xl font-bold">
            <Link to="/">{data.site.siteMetadata?.title || `Title`}</Link>
          </h1>

          <div>
            <button className="mx-3 border-2 rounded-xl p-3 text-xl shadow-lg hover:bg-gray-200">
              <Link to="/projects/apple-blossom">Apple Blossom</Link>
            </button>
            <button className="mx-3 border-2 rounded-xl p-3 text-xl shadow-lg hover:bg-gray-200">
              <Link to="/about">About</Link>
            </button>
          </div>
        </header>
        <main className="mx-auto p-5">{children}</main>
        <footer className="text-center p-5 mt-10 border-t-2 border-gray-400">
          © {new Date().getFullYear()} Cynthia Noyd
        </footer>
      </body>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
